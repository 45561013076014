import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Link = styled.span`
  text-decoration: underline;
  display: block;
  cursor: pointer;
`;

export const Message = styled.span`
  font-style: italic;
  color: #f44336;

  &:before {
    content: '"';
  }

  &:after {
    content: '"';
  }
`;
