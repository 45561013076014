import React from 'react';
import { Divider, Button } from 'antd';
import { connect } from 'react-redux';
import { FcSettings } from 'react-icons/fc';
import { FaPlay } from 'react-icons/fa';
import { MdOutlineSettingsBackupRestore } from 'react-icons/md';
import { IoCheckbox } from 'react-icons/io5';

import { Card } from 'src/components';
import { updateStageTask } from 'src/store/tasks/actions';

import { IProps } from './actions.type';
import { Title, Message } from './actions.style';
import useTask from './actions.use';

const ActionsComponent: React.FC<IProps> = (props) => {
  const { task } = props;

  const { handleUpdate, sending } = useTask(props);

  const buttons = (listId: number) => {
    switch (listId) {
      case 1:
        return (
          <>
            <Button
              loading={sending}
              disabled={!task.technical_advice || !task.technicianTasks.length}
              className="mb-2"
              type="primary"
              onClick={() => handleUpdate(2)}
            >
              <IoCheckbox className="me-2" />
              Finalizar
            </Button>
            {!task.technical_advice && (
              <small>
                <b>Atenção:</b> Você precisa preencher o parecer técnico para
                finalizar uma ordem de serviço.
              </small>
            )}
          </>
        );
      case 3:
        return (
          <Button
            loading={sending}
            disabled={!task.technicianTasks.length}
            className="mb-2"
            type="primary"
            onClick={() => handleUpdate(1)}
          >
            <MdOutlineSettingsBackupRestore className="me-2" />
            Restaurar
          </Button>
        );
      default:
        return (
          <Button
            loading={sending}
            disabled={!task.technicianTasks.length}
            className="mb-2"
            type="primary"
            onClick={() => handleUpdate(1)}
          >
            <FaPlay className="me-2" />
            Iniciar
          </Button>
        );
    }
  };

  return (
    <Card>
      <Title>
        <FcSettings size={20} className="me-2" />
        <Title>Ações</Title>
      </Title>
      <Divider className="mt-2 mb-3" />
      {buttons(props.listId)}
      <Message onClick={() => handleUpdate(3)}>Pausar tarefa</Message>
    </Card>
  );
};

export default connect(undefined, { updateStageTask })(ActionsComponent);
