import { Dispatch } from 'react';

import TasksService from 'src/services/tasks.service';
import { ITask, ICreateTaskInput } from 'src/interfaces/task';
import { request } from 'src/utils';
import { store } from 'src/store';

import { FETCH_TASKS, FETCH_TASK, TasksActionTypes } from './types';

export const fetchTasks =
  () => async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      const response: ITask[] = await TasksService.all();

      dispatch({
        type: FETCH_TASKS,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const fetchTask =
  (id: string) => async (dispatch: Dispatch<TasksActionTypes>) => {
    try {
      const response: ITask = await TasksService.current(id);

      dispatch({
        type: FETCH_TASK,
        payload: response,
      });

      return response;
    } catch (err) {
      request.errors(err);
    }
  };

export const createTask = (input: ICreateTaskInput | any) => async () => {
  input.userId = store.getState().auth.user.id;
  input.status = 1;
  input.urgencyDegree = Number(input.urgencyDegree[0]);
  input.occurredAt = new Date(input.occurredAt).toISOString();

  try {
    const response: ITask = await TasksService.create(input);

    return response;
  } catch (err) {
    request.errors(err);
  }
};

export const updateStageTask = (id: string, stage: number) => async () => {
  try {
    const response: ITask = await TasksService.update(id, {
      stage: Number(stage),
    });

    return response;
  } catch (err) {
    request.errors(err);
  }
};

export const updateTask = (id: string, input: any) => async () => {
  if (input?.urgencyDegree) {
    input.urgencyDegree = Number(input.urgencyDegree[0] || input.urgencyDegree);
  }

  try {
    const response: ITask = await TasksService.update(id, input);
    return response;
  } catch (err) {
    request.errors(err);
  }
};

export const removeTask = (id: string) => async () => {
  try {
    const response: any = await TasksService.remove(id);

    return response;
  } catch (err) {
    request.errors(err);
  }
};
