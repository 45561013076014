import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { IUpdateTaskInput } from 'src/interfaces/task';

import { IProps } from './advice.type';

const useTask = (props: IProps) => {
  const dispatch = useDispatch();

  const [sending, setSending] = useState(false);
  const [editing, setEditing] = useState(false);
  const [task, setTask] = useState(props.task);

  const { handleEditing, cardId } = props;

  const onEditing = () => {
    setEditing(!editing);
  };

  const handleSubmit = async (values: IUpdateTaskInput | any) => {
    setSending(true);

    try {
      const data = await props.updateTask(cardId, values);

      if (!!data) {
        dispatch({
          type: 'CHANGE_CARD_TEXT',
          payload: { cardId: cardId, cardText: data },
        });

        setTask(data);
      }
    } finally {
      handleEditing(false);
      setSending(false);
      setEditing(!editing);
    }
  };

  return {
    editing,
    onEditing,
    handleSubmit,
    sending,
    task,
  };
};

export default useTask;
