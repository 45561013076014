import { useState } from 'react';

import { Notification } from 'src/utils';
import { ITask, TaskStage } from 'src/interfaces/task';

import { IProps } from './actions.type';

const useTask = (props: IProps) => {
  const [sending, setSending] = useState(false);
  const [task, setTask] = useState<ITask>(props.task);

  const updateStageTask = async (taskId: any, stage: TaskStage) => {
    try {
      const response = await props.updateStageTask(taskId, stage);

      if (!!response) {
        setTask(response);
      }
    } catch (err: any) {
      Notification.error({
        message:
          'Ocorreu um erro ao carregar as tarefas, tente novamente mais tarde.',
      });

      return false;
    }

    return true;
  };

  const handleUpdate = async (stage: TaskStage) => {
    try {
      const response = await updateStageTask(task.id, stage);

      if (response) {
        setSending(response);
        props.handleModal();
      }
    } finally {
      setSending(false);
    }
  };

  const handleDelete = async () => {};

  return { handleUpdate, handleDelete, sending, task };
};

export default useTask;
